import * as React from "react";
import { Helmet } from "react-helmet";
import Redeemables from "../components/redeemables.js";

const RedeemablesPage = () => {
  const [redeemablesHeight, setRedeemablesHeight] = React.useState(0);
  React.useEffect(() => {
    window !== "undefined" && window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Redeemables - CULT&RAIN&trade;</title>
        <link rel="canonical" href="https://www.cultandrain.com/redeemables" />
        <meta
          name="description"
          content="The first luxury fashion brand born from the crypto universe merging NFTs with exclusive, physical redeemables. Join our Web3 revolution."
        />
      </Helmet>
      <Redeemables isPage={true} total={setRedeemablesHeight} />
    </main>
  );
};

export default RedeemablesPage;
