import * as React from "react";
import "../styles/redeemables.css";
import IconSmilesss from "../images/2.0/icon-smilesss.png";
import WhiteCrystal from "../images/2.0/white-crystal.png";
import TopazCrystal from "../images/2.0/topaz-crystal.png";
import SapphireCrystal from "../images/2.0/sapphire-crystal.png";
import EmeraldCrystal from "../images/2.0/emerald-crystal.png";
import RubyCrystal from "../images/2.0/ruby-crystal.png";
import OpalCrystal from "../images/2.0/opal-crystal.png";
import Icons from "../components/icons.js";
import HomeSVG from "../images/2.0/home.svg";
import Footer from "../components/footer.js";

const isBrowser = typeof window !== "undefined";

const Star = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className="star"
    >
      <path
        d="M7 0L8.5716 4.83688H13.6574L9.5429 7.82624L11.1145 12.6631L7 9.67376L2.8855 12.6631L4.4571 7.82624L0.342604 4.83688H5.4284L7 0Z"
        fill={props.color}
      />
    </svg>
  );
};
// markup
const Redeemables = (props) => {

  const [footerVisible, setFooterVisible] = React.useState(false);

  return (
    <div
      className={"redeemables" + (props.isPage ? " redeemables-page" : "")}
    >
      <div className="wrapper">
        {props.isPage && (
          <div>
            <a href="/" className="homebtn">
              <img src={HomeSVG} />
            </a>
            <Icons mintVisible={true} page="genesis" />
          </div>
        )}
        <div className="redeemables-header">
          <h1>
            <svg
              width="575"
              height="133"
              viewBox="0 0 575 133"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M58.4322 57.75C54.5222 59.79 50.4422 61.2634 46.1922 62.17C41.9422 63.0767 37.6072 63.53 33.1872 63.53C28.4838 63.53 24.1488 62.7934 20.1822 61.32C16.2155 59.8467 12.7872 57.75 9.89716 55.03C7.00716 52.2534 4.74049 48.91 3.09716 45C1.51049 41.09 0.717163 36.7267 0.717163 31.91C0.717163 27.0934 1.51049 22.73 3.09716 18.82C4.74049 14.91 7.00716 11.595 9.89716 8.87504C12.7872 6.09837 16.2155 3.97337 20.1822 2.50004C24.1488 1.02671 28.4838 0.290039 33.1872 0.290039C38.0038 0.290039 42.4238 0.885038 46.4472 2.07504C50.5272 3.20837 54.0972 5.1917 57.1572 8.02504L47.8072 18.225C45.9938 16.355 43.9538 14.9384 41.6872 13.975C39.4205 13.0117 36.5872 12.53 33.1872 12.53C30.3538 12.53 27.7755 13.0117 25.4522 13.975C23.1855 14.9384 21.2305 16.2984 19.5872 18.055C17.9438 19.755 16.6688 21.795 15.7622 24.175C14.9122 26.555 14.4872 29.1334 14.4872 31.91C14.4872 34.7434 14.9122 37.35 15.7622 39.73C16.6688 42.0534 17.9438 44.0934 19.5872 45.85C21.2305 47.55 23.1855 48.8817 25.4522 49.845C27.7755 50.8084 30.3538 51.29 33.1872 51.29C36.0772 51.29 38.5705 50.9784 40.6672 50.355C42.7638 49.675 44.4355 48.995 45.6822 48.315V38.54H34.8872V26.3H58.4322V57.75Z"
                fill="white"
              />
              <path
                d="M65.6144 1.82004H106.499V14.06H78.8744V25.28H104.969V37.52H78.8744V49.76H108.029V62H65.6144V1.82004Z"
                fill="white"
              />
              <path
                d="M113.644 1.82004H131.664L157.249 43.64H157.419V1.82004H170.679V62H153.339L127.074 19.16H126.904V62H113.644V1.82004Z"
                fill="white"
              />
              <path
                d="M178.466 1.82004H219.351V14.06H191.726V25.28H217.821V37.52H191.726V49.76H220.881V62H178.466V1.82004Z"
                fill="white"
              />
              <path
                d="M256.246 16.525C255.169 15.165 253.696 14.1734 251.826 13.55C250.013 12.87 248.284 12.53 246.641 12.53C245.678 12.53 244.686 12.6434 243.666 12.87C242.646 13.0967 241.683 13.465 240.776 13.975C239.869 14.4284 239.133 15.0517 238.566 15.845C237.999 16.6384 237.716 17.6017 237.716 18.735C237.716 20.5484 238.396 21.9367 239.756 22.9C241.116 23.8634 242.816 24.685 244.856 25.365C246.953 26.045 249.191 26.725 251.571 27.405C253.951 28.085 256.161 29.0484 258.201 30.295C260.298 31.5417 262.026 33.2134 263.386 35.31C264.746 37.4067 265.426 40.2117 265.426 43.725C265.426 47.0684 264.803 49.9867 263.556 52.48C262.309 54.9734 260.609 57.0417 258.456 58.685C256.359 60.3284 253.894 61.5467 251.061 62.34C248.284 63.1334 245.338 63.53 242.221 63.53C238.311 63.53 234.684 62.935 231.341 61.745C227.998 60.555 224.881 58.6284 221.991 55.965L231.426 45.595C232.786 47.4084 234.486 48.825 236.526 49.845C238.623 50.8084 240.776 51.29 242.986 51.29C244.063 51.29 245.139 51.1767 246.216 50.95C247.349 50.6667 248.341 50.27 249.191 49.76C250.098 49.25 250.806 48.5984 251.316 47.805C251.883 47.0117 252.166 46.0767 252.166 45C252.166 43.1867 251.458 41.77 250.041 40.75C248.681 39.6734 246.953 38.7667 244.856 38.03C242.759 37.2934 240.493 36.5567 238.056 35.82C235.619 35.0834 233.353 34.0917 231.256 32.845C229.159 31.5984 227.403 29.955 225.986 27.915C224.626 25.875 223.946 23.1834 223.946 19.84C223.946 16.61 224.569 13.7767 225.816 11.34C227.119 8.90337 228.819 6.86337 230.916 5.22004C233.069 3.5767 235.534 2.35837 238.311 1.56504C241.088 0.715039 243.949 0.290039 246.896 0.290039C250.296 0.290039 253.583 0.771705 256.756 1.73504C259.929 2.69837 262.791 4.31337 265.341 6.58004L256.246 16.525Z"
                fill="white"
              />
              <path
                d="M270.135 1.82004H283.395V62H270.135V1.82004Z"
                fill="white"
              />
              <path
                d="M320.206 16.525C319.129 15.165 317.656 14.1734 315.786 13.55C313.973 12.87 312.244 12.53 310.601 12.53C309.638 12.53 308.646 12.6434 307.626 12.87C306.606 13.0967 305.643 13.465 304.736 13.975C303.829 14.4284 303.093 15.0517 302.526 15.845C301.959 16.6384 301.676 17.6017 301.676 18.735C301.676 20.5484 302.356 21.9367 303.716 22.9C305.076 23.8634 306.776 24.685 308.816 25.365C310.913 26.045 313.151 26.725 315.531 27.405C317.911 28.085 320.121 29.0484 322.161 30.295C324.258 31.5417 325.986 33.2134 327.346 35.31C328.706 37.4067 329.386 40.2117 329.386 43.725C329.386 47.0684 328.763 49.9867 327.516 52.48C326.269 54.9734 324.569 57.0417 322.416 58.685C320.319 60.3284 317.854 61.5467 315.021 62.34C312.244 63.1334 309.298 63.53 306.181 63.53C302.271 63.53 298.644 62.935 295.301 61.745C291.958 60.555 288.841 58.6284 285.951 55.965L295.386 45.595C296.746 47.4084 298.446 48.825 300.486 49.845C302.583 50.8084 304.736 51.29 306.946 51.29C308.023 51.29 309.099 51.1767 310.176 50.95C311.309 50.6667 312.301 50.27 313.151 49.76C314.058 49.25 314.766 48.5984 315.276 47.805C315.843 47.0117 316.126 46.0767 316.126 45C316.126 43.1867 315.418 41.77 314.001 40.75C312.641 39.6734 310.913 38.7667 308.816 38.03C306.719 37.2934 304.453 36.5567 302.016 35.82C299.579 35.0834 297.313 34.0917 295.216 32.845C293.119 31.5984 291.363 29.955 289.946 27.915C288.586 25.875 287.906 23.1834 287.906 19.84C287.906 16.61 288.529 13.7767 289.776 11.34C291.079 8.90337 292.779 6.86337 294.876 5.22004C297.029 3.5767 299.494 2.35837 302.271 1.56504C305.048 0.715039 307.909 0.290039 310.856 0.290039C314.256 0.290039 317.543 0.771705 320.716 1.73504C323.889 2.69837 326.751 4.31337 329.301 6.58004L320.206 16.525Z"
                fill="white"
              />
              <path
                d="M354.416 1.82004H374.306C379.123 1.82004 383.684 2.35837 387.991 3.43504C392.354 4.51171 396.151 6.26837 399.381 8.70504C402.611 11.085 405.161 14.2017 407.031 18.055C408.958 21.9084 409.921 26.5834 409.921 32.08C409.921 36.9534 408.986 41.26 407.116 45C405.303 48.6834 402.838 51.8 399.721 54.35C396.604 56.8434 393.006 58.7417 388.926 60.045C384.846 61.3484 380.568 62 376.091 62H354.416V1.82004ZM367.676 49.76H374.561C377.621 49.76 380.454 49.4484 383.061 48.825C385.724 48.2017 388.019 47.1817 389.946 45.765C391.873 44.2917 393.374 42.3934 394.451 40.07C395.584 37.69 396.151 34.8 396.151 31.4C396.151 28.4534 395.584 25.9034 394.451 23.75C393.374 21.54 391.901 19.7267 390.031 18.31C388.161 16.8934 385.951 15.845 383.401 15.165C380.908 14.4284 378.273 14.06 375.496 14.06H367.676V49.76Z"
                fill="white"
              />
              <path
                d="M413.913 1.82004H437.203C440.263 1.82004 443.153 2.13171 445.873 2.75504C448.65 3.3217 451.058 4.31337 453.098 5.73003C455.195 7.09004 456.838 8.96004 458.028 11.34C459.275 13.6634 459.898 16.5817 459.898 20.095C459.898 24.345 458.793 27.9717 456.583 30.975C454.373 33.9217 451.143 35.7917 446.893 36.585L462.193 62H446.298L433.718 37.945H427.173V62H413.913V1.82004ZM427.173 26.725H434.993C436.183 26.725 437.43 26.6967 438.733 26.64C440.093 26.5267 441.311 26.2717 442.388 25.875C443.465 25.4217 444.343 24.7417 445.023 23.835C445.76 22.9284 446.128 21.6534 446.128 20.01C446.128 18.48 445.816 17.2617 445.193 16.355C444.57 15.4484 443.776 14.7684 442.813 14.315C441.85 13.805 440.745 13.465 439.498 13.295C438.251 13.125 437.033 13.04 435.843 13.04H427.173V26.725Z"
                fill="white"
              />
              <path
                d="M460.8 31.91C460.8 27.0934 461.594 22.73 463.18 18.82C464.824 14.91 467.09 11.595 469.98 8.87504C472.87 6.09837 476.298 3.97337 480.265 2.50004C484.232 1.02671 488.567 0.290039 493.27 0.290039C497.973 0.290039 502.308 1.02671 506.275 2.50004C510.242 3.97337 513.67 6.09837 516.56 8.87504C519.45 11.595 521.688 14.91 523.275 18.82C524.918 22.73 525.74 27.0934 525.74 31.91C525.74 36.7267 524.918 41.09 523.275 45C521.688 48.91 519.45 52.2534 516.56 55.03C513.67 57.75 510.242 59.8467 506.275 61.32C502.308 62.7934 497.973 63.53 493.27 63.53C488.567 63.53 484.232 62.7934 480.265 61.32C476.298 59.8467 472.87 57.75 469.98 55.03C467.09 52.2534 464.824 48.91 463.18 45C461.594 41.09 460.8 36.7267 460.8 31.91ZM474.57 31.91C474.57 34.7434 474.995 37.35 475.845 39.73C476.752 42.0534 478.027 44.0934 479.67 45.85C481.314 47.55 483.268 48.8817 485.535 49.845C487.858 50.8084 490.437 51.29 493.27 51.29C496.103 51.29 498.654 50.8084 500.92 49.845C503.243 48.8817 505.227 47.55 506.87 45.85C508.514 44.0934 509.76 42.0534 510.61 39.73C511.517 37.35 511.97 34.7434 511.97 31.91C511.97 29.1334 511.517 26.555 510.61 24.175C509.76 21.795 508.514 19.755 506.87 18.055C505.227 16.2984 503.243 14.9384 500.92 13.975C498.654 13.0117 496.103 12.53 493.27 12.53C490.437 12.53 487.858 13.0117 485.535 13.975C483.268 14.9384 481.314 16.2984 479.67 18.055C478.027 19.755 476.752 21.795 475.845 24.175C474.995 26.555 474.57 29.1334 474.57 31.91Z"
                fill="white"
              />
              <path
                d="M529.753 1.82004H552.108C555.224 1.82004 558.171 2.10337 560.948 2.67004C563.724 3.23671 566.133 4.22837 568.173 5.64504C570.269 7.00504 571.913 8.87504 573.103 11.255C574.349 13.5784 574.973 16.525 574.973 20.095C574.973 23.6084 574.406 26.555 573.273 28.935C572.139 31.315 570.581 33.2134 568.598 34.63C566.615 36.0467 564.263 37.0667 561.543 37.69C558.823 38.2567 555.876 38.54 552.703 38.54H543.013V62H529.753V1.82004ZM543.013 27.32H551.853C553.043 27.32 554.176 27.2067 555.253 26.98C556.386 26.7534 557.378 26.385 558.228 25.875C559.134 25.3084 559.843 24.5717 560.353 23.665C560.919 22.7017 561.203 21.5117 561.203 20.095C561.203 18.565 560.835 17.3467 560.098 16.44C559.418 15.4767 558.511 14.74 557.378 14.23C556.301 13.72 555.083 13.4084 553.723 13.295C552.363 13.125 551.059 13.04 549.813 13.04H543.013V27.32Z"
                fill="white"
              />
              <path
                d="M170.326 107.795C170.326 111.365 169.788 114.652 168.711 117.655C167.635 120.658 166.02 123.265 163.866 125.475C161.77 127.685 159.135 129.413 155.961 130.66C152.788 131.907 149.133 132.53 144.996 132.53C140.803 132.53 137.12 131.907 133.946 130.66C130.773 129.413 128.11 127.685 125.956 125.475C123.86 123.265 122.273 120.658 121.196 117.655C120.12 114.652 119.581 111.365 119.581 107.795V70.82H132.841V107.285C132.841 109.155 133.125 110.883 133.691 112.47C134.315 114.057 135.165 115.445 136.241 116.635C137.318 117.768 138.593 118.675 140.066 119.355C141.596 119.978 143.24 120.29 144.996 120.29C146.753 120.29 148.368 119.978 149.841 119.355C151.315 118.675 152.59 117.768 153.666 116.635C154.743 115.445 155.565 114.057 156.131 112.47C156.755 110.883 157.066 109.155 157.066 107.285V70.82H170.326V107.795Z"
                fill="white"
              />
              <path
                d="M189.229 82.55H172.059V70.82H219.659V82.55H202.489V131H189.229V82.55Z"
                fill="white"
              />
              <path
                d="M221.223 70.82H234.483V131H221.223V70.82Z"
                fill="white"
              />
              <path
                d="M242.054 70.82H255.314V118.76H279.879V131H242.054V70.82Z"
                fill="white"
              />
              <path
                d="M282.029 70.82H295.289V131H282.029V70.82Z"
                fill="white"
              />
              <path
                d="M313.995 82.55H296.825V70.82H344.425V82.55H327.255V131H313.995V82.55Z"
                fill="white"
              />
              <path
                d="M345.989 70.82H359.249V131H345.989V70.82Z"
                fill="white"
              />
              <path
                d="M366.82 70.82H407.705V83.06H380.08V94.28H406.175V106.52H380.08V118.76H409.235V131H366.82V70.82Z"
                fill="white"
              />
              <path
                d="M444.6 85.525C443.523 84.165 442.05 83.1734 440.18 82.55C438.367 81.87 436.638 81.53 434.995 81.53C434.032 81.53 433.04 81.6434 432.02 81.87C431 82.0967 430.037 82.465 429.13 82.975C428.223 83.4284 427.487 84.0517 426.92 84.845C426.353 85.6384 426.07 86.6017 426.07 87.735C426.07 89.5484 426.75 90.9367 428.11 91.9C429.47 92.8634 431.17 93.685 433.21 94.365C435.307 95.045 437.545 95.725 439.925 96.405C442.305 97.085 444.515 98.0484 446.555 99.295C448.652 100.542 450.38 102.213 451.74 104.31C453.1 106.407 453.78 109.212 453.78 112.725C453.78 116.068 453.157 118.987 451.91 121.48C450.663 123.973 448.963 126.042 446.81 127.685C444.713 129.328 442.248 130.547 439.415 131.34C436.638 132.133 433.692 132.53 430.575 132.53C426.665 132.53 423.038 131.935 419.695 130.745C416.352 129.555 413.235 127.628 410.345 124.965L419.78 114.595C421.14 116.408 422.84 117.825 424.88 118.845C426.977 119.808 429.13 120.29 431.34 120.29C432.417 120.29 433.493 120.177 434.57 119.95C435.703 119.667 436.695 119.27 437.545 118.76C438.452 118.25 439.16 117.598 439.67 116.805C440.237 116.012 440.52 115.077 440.52 114C440.52 112.187 439.812 110.77 438.395 109.75C437.035 108.673 435.307 107.767 433.21 107.03C431.113 106.293 428.847 105.557 426.41 104.82C423.973 104.083 421.707 103.092 419.61 101.845C417.513 100.598 415.757 98.955 414.34 96.915C412.98 94.875 412.3 92.1834 412.3 88.84C412.3 85.61 412.923 82.7767 414.17 80.34C415.473 77.9034 417.173 75.8634 419.27 74.22C421.423 72.5767 423.888 71.3584 426.665 70.565C429.442 69.715 432.303 69.29 435.25 69.29C438.65 69.29 441.937 69.7717 445.11 70.735C448.283 71.6984 451.145 73.3134 453.695 75.58L444.6 85.525Z"
                fill="white"
              />
            </svg>
          </h1>
          <h2>“CULTR PASS”</h2>
          <p>
            Models TITAN, ATLAS, VEGA, ORION
          </p>
          <h3>
            INTRODUCING NFT HOLDER REWARDS
            <br />
            <small>(ALL RARITIES ELIGIBLE)</small>
          </h3>
        </div>
        <div className="redeemables-body no-middle redeemables-smiles">
          <div
            className="redeemable-column redeemable-column-alone"
            style={{ width: "100%" }}
          >
            <p className="redeemables-subtitle" style={{minHeight:'inherit',paddingBottom:'20px'}}>
              <span className="opal">THE ONE #1</span>
            </p>
            <div style={{textAlign:'center'}}>
              <img
                src={OpalCrystal}
                alt="Opal Crystal"
                className="crystal"
                width="25"
                height="70"
              />
            </div>
            <ul className="opal" style={{textAlign:'center',marginTop:'20px'}}>
              <li>&bull; Free Mint Drop 003</li>
              <li>&bull; Free Mint Drop 002</li>
              <li>&bull; Free Mint CULTR Pad</li>
              <li>&bull; Free Mint CULTR Avatar</li>
              <li>&bull; Exclusive Air Drop Rewards (TIER 1)</li>
              <li>&bull; 50% Future E-com Discount (Limited to 1 drop per year)</li>
              <li>&bull; 50% Future Pop-Up Discount (Limited to 1 drop per year)</li>
              <li>&bull; CULTR Giveaway Access</li>
              <li>&bull; Pre-Mint Access to Future Drops with 15% Discount</li>
              <li>&bull; Worldwide Free Shipping (Select Destinations)</li>
              <li>&bull; Exclusive Access to CULT&RAIN Inner Circle Events</li>
              <li>&bull; Private Discord ChannelRarity Colored Discord Title</li>
            </ul>
          </div>
        </div>
        <div className="redeemables-body no-middle redeemables-smiles" style={{marginTop:'5em',}}>
          <div
            className="redeemable-column redeemable-column-alone"
            style={{ width: "100%" }}
          >
            <p className="redeemables-subtitle" style={{minHeight:'inherit',paddingBottom:'20px'}}>
              <span className="opal">OPAL #001/100</span>
            </p>
            <div style={{textAlign:'center'}}>
              <img
                src={OpalCrystal}
                alt="Opal Crystal"
                className="crystal"
                width="25"
                height="70"
              />
            </div>
            <ul className="opal" style={{textAlign:'center',marginTop:'20px'}}>
              <li>&bull; Free Mint Drop 002</li>
              <li>&bull; Free Mint CULTR Pad</li>
              <li>&bull; Free Mint CULTR Avatar</li>
              <li>&bull; Exclusive Air Drop Rewards (TIER 1)</li>
              <li>&bull; 25% Future E-com Discount (Limited to 1 drop per year)</li>
              <li>&bull; 25% Future Pop-Up Discount (Limited to 1 drop per year)</li>
              <li>&bull; CULTR Giveaway Access</li>
              <li>&bull; Pre-Mint Access to Future Drops with 15% Discount</li>
              <li>&bull; Worldwide Free Shipping (Select Destinations)</li>
              <li>&bull; Exclusive Access to CULT&RAIN Inner Circle Events</li>
              <li>&bull; Private Discord ChannelRarity Colored Discord Title</li>
            </ul>
          </div>
        </div>
        <div className="redeemables-body no-middle redeemables-smiles" style={{marginTop:'5em',}}>
          <div
            className="redeemable-column redeemable-column-alone"
            style={{ width: "100%" }}
          >
            <p className="redeemables-subtitle" style={{minHeight:'inherit',paddingBottom:'20px'}}>
              <span className="ruby">RUBY #002-004/100</span>
            </p>
            <div style={{textAlign:'center'}}>
              <img
                src={RubyCrystal}
                alt="Ruby Crystal"
                className="crystal"
                width="25"
                height="70"
              />
            </div>
            <ul className="ruby" style={{textAlign:'center',marginTop:'20px'}}>
              <li>&bull; Free Mint CULTR Pad</li>
              <li>&bull; Free Mint CULTR Avatar</li>
              <li>&bull; Exclusive Airdrop Rewards (TIER 2)</li>
              <li>&bull; 25% Future E-com Discount (Limited to 1 drop per year)</li>
              <li>&bull; 25% Future Pop-Up Discount (Limited to 1 drop per year)</li>
              <li>&bull; CULTR Giveaway Access</li>
              <li>&bull; Pre-Mint Access to Future Drops with 15% Discount</li>
              <li>&bull; Worldwide Free Shipping (Select Destinations)</li>
              <li>&bull; Exclusive Access to CULT&RAIN Inner Circle Events</li>
              <li>&bull; Private Discord Channel</li>
<li>&bull; Rarity Colored Discord Title</li>
            </ul>
          </div>
        </div>
        <div className="redeemables-body no-middle redeemables-smiles" style={{marginTop:'5em',}}>
          <div
            className="redeemable-column redeemable-column-alone"
            style={{ width: "100%" }}
          >
            <p className="redeemables-subtitle" style={{minHeight:'inherit',paddingBottom:'20px'}}>
              <span className="emerald">EMERALD #005-012/100</span>
            </p>
            <div style={{textAlign:'center'}}>
              <img
                src={EmeraldCrystal}
                alt="Emerald Crystal"
                className="crystal"
                width="25"
                height="70"
              />
            </div>
            <ul className="emerald" style={{textAlign:'center',marginTop:'20px'}}>
              <li>&bull; Free Mint CULTR Pad</li>
              <li>&bull; Free Mint CULTR Avatar</li>
              <li>&bull; Exclusive Airdrop Rewards (TIER 3)</li>
              <li>&bull; 25% Future E-com Discount (Limited to 1 drop per year)</li>
              <li>&bull; 25% Future Pop-Up Discount (Limited to 1 drop per year)</li>
              <li>&bull; CULTR Giveaway Access</li>
              <li>&bull; Pre-Mint Access to Future Drops with 15% Discount</li>
              <li>&bull; Worldwide Free Shipping (Select Destinations)</li>
              <li>&bull; Exclusive Access to CULT&RAIN Inner Circle Events</li>
              <li>&bull; Private Discord Channel</li>
              <li>&bull; Rarity Colored Discord Title</li>
            </ul>
          </div>
        </div>
        <div className="redeemables-body no-middle redeemables-smiles" style={{marginTop:'5em',}}>
          <div
            className="redeemable-column redeemable-column-alone"
            style={{ width: "100%" }}
          >
            <p className="redeemables-subtitle" style={{minHeight:'inherit',paddingBottom:'20px'}}>
              <span className="sapphire">SAPPHIRE #013-023/100</span>
            </p>
            <div style={{textAlign:'center'}}>
              <img
                src={SapphireCrystal}
                alt="Sapphire Crystal"
                className="crystal"
                width="25"
                height="70"
              />
            </div>
            <ul className="sapphire" style={{textAlign:'center',marginTop:'20px'}}>
              <li>&bull; Free Mint CULTR Pad</li>
              <li>&bull; Free Mint CULTR Avatar</li>
              <li>&bull; Exclusive Airdrop Rewards (TIER 4)</li>
              <li>&bull; 25% Future E-com Discount (Limited to 1 drop per year)</li>
              <li>&bull; 25% Future Pop-Up Discount (Limited to 1 drop per year)</li>
              <li>&bull; CULTR Giveaway Access</li>
              <li>&bull; Pre-Mint Access to Future Drops with 15% Discount</li>
              <li>&bull; Worldwide Free Shipping (Select Destinations)</li>
              <li>&bull; VIP Access to CULT&RAIN Events</li>
              <li>&bull; Private Discord Channel</li>
              <li>&bull; Rarity Colored Discord Title</li>
            </ul>
          </div>
        </div>
        <div className="redeemables-body no-middle redeemables-smiles" style={{marginTop:'5em',}}>
          <div
            className="redeemable-column redeemable-column-alone"
            style={{ width: "100%" }}
          >
            <p className="redeemables-subtitle" style={{minHeight:'inherit',paddingBottom:'20px'}}>
              <span className="topaz">TOPAZ #024-056/100</span>
            </p>
            <div style={{textAlign:'center'}}>
              <img
                src={TopazCrystal}
                alt="Topaz Crystal"
                className="crystal"
                width="25"
                height="70"
              />
            </div>
            <ul className="topaz" style={{textAlign:'center',marginTop:'20px'}}>
              <li>&bull; Free Mint CULTR Pad</li>
              <li>&bull; Free Mint CULTR Avatar</li>
              <li>&bull; Exclusive Air Drop Rewards (TIER 5)</li>
              <li>&bull; 25% Future E-com Discount (Limited to 1 drop per year)</li>
              <li>&bull; 25% Future Pop-Up Discount (Limited to 1 drop per year)</li>
              <li>&bull; CULTR Giveaway Access</li>
              <li>&bull; Pre-Mint Access to Future Drops with 15% Discount</li>
              <li>&bull; Worldwide Free Shipping (Select Destinations)</li>
              <li>&bull; VIP Access to CULT&RAIN Events</li>
              <li>&bull; Private Discord Channel</li>
              <li>&bull; Rarity Colored Discord Title</li>
            </ul>
          </div>
        </div>
        <div className="redeemables-body no-middle redeemables-smiles" style={{marginTop:'5em',}}>
          <div
            className="redeemable-column redeemable-column-alone"
            style={{ width: "100%" }}
          >
            <p className="redeemables-subtitle" style={{minHeight:'inherit',paddingBottom:'20px'}}>
              <span className="crystal">CRYSTAL #057-100/100</span>
            </p>
            <div style={{textAlign:'center'}}>
              <img
                src={WhiteCrystal}
                alt="Crystal"
                className="crystal"
                width="25"
                height="70"
              />
            </div>
            <ul className="crystal" style={{textAlign:'center',marginTop:'20px'}}>
              <li>&bull; Free Mint CULTR Pad</li>
              <li>&bull; Free Mint CULTR Avatar</li>
              <li>&bull; Exclusive Airdrop Rewards (TIER 6)</li>
              <li>&bull; 25% Future E-Com Discount (Limited to 1 drop per year)</li>
              <li>&bull; 25% Future Pop-Up Discount (Limited to 1 drop per year)</li>
              <li>&bull; CULTR Giveaway Access</li>
              <li>&bull; Pre-Mint Access to Future Drops with 15% Discount</li>
              <li>&bull; Worldwide Free Shipping (Select Destinations)</li>
              <li>&bull; VIP Access to CULT&RAIN Events</li>
              <li>&bull; Private Discord Channel</li>
              <li>&bull; Rarity Colored Discord Title</li>
            </ul>
          </div>
        </div>
        <div className="redeemables-spacer"></div>
        <div className="redeemables-spacer"></div>
        <div className="redeemables-body no-middle redeemables-smiles">
          <div
            className="redeemable-column redeemable-column-alone"
            style={{ width: "100%" }}
          >
            <p className="redeemables-subtitle redeemables-subtitle-whale">
              <span className="redeemables-subtitle-blue">CULTR COLLECTOR</span>
              <br />
              WHALE REWARDS
              <br />
              <small>
                Added bonus and utility for our ULTIMATE 5+ NFT collectors
              </small>
            </p>
            <h5
              style={{
                textAlign: "center",
                fontSize: "1.5em",
                fontFamily: "Avenir Black",
                marginBottom: "0em",
              }}
            >
              HOLD 5 NFTs OR MORE
            </h5>
            <ul style={{marginTop:'20px',textAlign:'center'}}>
              <li>&bull; Pre-Mint Access to Future Drops with Up To 25% Discount</li>
              <li>&bull; 35% Future E-com Discount (Limited to 1 drop per year)</li>
              <li>&bull; 35% Future Pop-Up Discount (Limited to 1 drop per year)</li>
              <li>&bull; Exclusive Skin for CULTR Pad</li>
              <li>&bull; Exclusive Skin for CULTR Avatar</li>
              <li>&bull; Private Discord Channel</li>
              <li>&bull; Exclusive Colored Discord Title</li>
            </ul>
          </div>
        </div>
        </div>
      {props.isPage && <div className="footer-bar" />}
      {props.isPage && <Footer setFooterVisible={setFooterVisible} />}
    </div>
  );
};

export default Redeemables;
